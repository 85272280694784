import { Box, Center, Grid, VStack } from "@chakra-ui/react";
import { moneyString, useIsMobile } from "@doorstead/components";
import { ReactNode } from "react";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";

function InfoBox({
  label,
  hasBottomLine = false,
  hasRightLine = false,
  children,
}: {
  label: string;
  hasBottomLine?: boolean;
  hasRightLine?: boolean;
  children: ReactNode;
}) {
  return (
    <Center
      w="100%"
      borderBottom={hasBottomLine ? "1px solid #E2E8F0" : ""}
      borderRight={hasRightLine ? "1px solid #E2E8F0" : ""}
      p={3}
    >
      <VStack flex={1} fontWeight="bold" alignItems="flex-start">
        <Box fontSize="sm" color="gray.500">
          {label}
        </Box>
        <Box color="gray.700">{children}</Box>
      </VStack>
    </Center>
  );
}

export function InfoGrid() {
  const {
    applicationRequest: {
      negotiatedRent,
      property: { sqft, bedrooms, bathrooms },
    },
  } = useFetchedApplicant();
  const isMobile = useIsMobile();
  return (
    <Grid
      w="100%"
      templateColumns={isMobile ? "repeat(2, 2fr)" : "repeat(4, 1fr)"}
      gap={0}
      bgColor="gray.50"
      borderRadius={5}
    >
      <InfoBox hasBottomLine={isMobile} hasRightLine label="Monthly rent">
        {moneyString(negotiatedRent, false)}
      </InfoBox>
      {sqft && (
        <InfoBox
          hasRightLine={!isMobile}
          hasBottomLine={isMobile}
          label="Size"
        >{`${sqft.toLocaleString()} sqft`}</InfoBox>
      )}
      {bedrooms && (
        <InfoBox hasRightLine label="Bedroom">
          {bedrooms.toLocaleString()}
        </InfoBox>
      )}
      {bathrooms && <InfoBox label="Bathroom">{bathrooms.toLocaleString()}</InfoBox>}
    </Grid>
  );
}
