import { DoorsteadProvider } from "@doorstead/components/DoorsteadProvider";
import { LinkProvider } from "@doorstead/components/hooks/useReactRouterLink";
import * as Sentry from "@sentry/react";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { StatsigSetup } from "src";
import { GOOGLE_MAP_KEY } from "../../../config";
import { useApplicantId } from "../../../hooks/applicants/useApplicantId";
import { useFetchApplicationMe } from "../../../hooks/applicants/useFetchApplicationMe";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { Provider as GoogleMapProvider } from "../../../hooks/useGoogleMapKey";
import { Provider as PropertyStateProvider } from "../../../hooks/usePropertyState";
import { Error } from "../../Error";
import { ApplicantLayout } from "./ApplicantLayout";

function CheckApplicationData({ children }: { children: React.ReactNode }) {
  const applicantId = useApplicantId();
  const { isFetching, isFetched, data } = useFetchApplicationMe();
  if (isFetching && !isFetched) {
    return null;
  } else if (!data || data.applicantById === null) {
    Sentry.addBreadcrumb({
      category: `#[Applicant-Portal]can not find the applicant: ${applicantId}`,
      message: "Request",
      data: { data, applicantId },
      level: "error",
    });
    return <Error title="Can not find this application" />;
  } else {
    return <>{children}</>;
  }
}

function StatsigListingId({ children }: { children: React.ReactNode }) {
  const applicationMe = useFetchedApplicationMe();

  return (
    <StatsigSetup customIDs={{ listing_id: applicationMe.applicantById.applicationRequest.listing.listingId }}>
      {children}
    </StatsigSetup>
  );
}

function PropertyState({ children }: { children: React.ReactNode }) {
  const applicationMe = useFetchedApplicationMe();

  return (
    <PropertyStateProvider propertyState={applicationMe.applicantById.applicationRequest.property.state}>
      {children}
    </PropertyStateProvider>
  );
}

export function ApplicantPortalProvider({ children }: { children: React.ReactNode }) {
  // 1. Install Doorstead components
  // 2. Install Google Map Key
  // 3. Check if the application data is fetched
  // 4. Install Applicant Layout

  return (
    <LinkProvider
      value={{
        Link(linkProps: any) {
          return <Link {...(linkProps as LinkProps)} />;
        },
      }}
    >
      <DoorsteadProvider>
        <GoogleMapProvider value={GOOGLE_MAP_KEY}>
          <CheckApplicationData>
            <ApplicantLayout>
              <PropertyState>
                <StatsigListingId>{children}</StatsigListingId>
              </PropertyState>
            </ApplicantLayout>
          </CheckApplicationData>
        </GoogleMapProvider>
      </DoorsteadProvider>
    </LinkProvider>
  );
}
