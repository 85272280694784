import produce from "immer";
import { useApplicantId } from "../../../../../hooks/applicants/useApplicantId";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { useLogConversionEvent } from "../../../../../hooks/useLogConversionEvent";
import { useTrackImpressionEvent } from "../../../../../hooks/useTrackImpressionEvent";
import { getUuid } from "../../../../../utils/getUuid";
import { AddCotenantsAndGuarantorsPageV2 } from "../../../AddCotenantsAndGuarantorsPageV2";
import {
  ApplicantTypeV2,
  NewApplicantWithIdV2,
} from "../../../AddCotenantsAndGuarantorsPageV2/NewApplicantsCardV2/typings";
import { useInternalState } from "../useInternalState";
import { View, useView } from "../useView";

export function AddCoTenantsAndGuarantorsView() {
  const applicantId = useApplicantId();
  const logConversionEvent = useLogConversionEvent();
  const { howManyAdults, haveGuarantor } = useInternalState();
  const applicant = useFetchedApplicant();
  const { setView } = useView();
  useTrackImpressionEvent({
    data: {
      applicantId,
      howManyAdults,
      haveGuarantor,
    },
    email: applicant.doorsteadUser.email || "",
    propertyId: applicant.applicationRequest.property?.propertyId || "",
    action: "TP_APPLICANT_INVITE_COTENANT_ADD_VIEWED",
    surface: "APPLICANT_PORTAL",
  });

  const initCoTenants: NewApplicantWithIdV2[] = produce<NewApplicantWithIdV2[]>([], (draft) => {
    if (howManyAdults !== null) {
      for (let i = 1; i <= howManyAdults; i++) {
        if (i === 1) {
          draft.push({
            id: applicantId,
            type: ApplicantTypeV2.TENANT,
            email: applicant.doorsteadUser.email,
            firstName: applicant.doorsteadUser.firstName,
            lastName: applicant.doorsteadUser.lastName,
            disabled: true,
            removable: false,
            createdAt: applicant.createdAt,
          });
        } else {
          draft.push({
            id: getUuid(),
            type: ApplicantTypeV2.TENANT,
            email: "",
            firstName: "",
            lastName: "",
            disabled: false,
            removable: true,
            createdAt: new Date().toISOString(),
          });
        }
      }
    }
  });

  const initGuarantors: NewApplicantWithIdV2[] = produce<NewApplicantWithIdV2[]>([], (draft) => {
    if (haveGuarantor) {
      draft.push({
        id: getUuid(),
        type: ApplicantTypeV2.GUARANTOR,
        email: "",
        firstName: "",
        lastName: "",
        disabled: false,
        removable: true,
        createdAt: new Date().toISOString(),
      });
    }
  });
  return (
    <AddCotenantsAndGuarantorsPageV2
      initCoTenants={initCoTenants}
      initGuarantors={initGuarantors}
      onBackClick={() => {
        logConversionEvent({
          email: applicant.doorsteadUser.email || "",
          propertyId: applicant.applicationRequest.property?.propertyId || "",
          action: "TP_APPLICANT_INVITE_COTENANT_ADD_BACK_CLICKED",
          surface: "APPLICANT_PORTAL",
          data: {
            applicantId: applicant.applicantId,
            howManyAdults,
            haveGuarantor,
          },
        });
        setView(View.GetStartedView);
      }}
      onSuccess={async ({ coTenants, guarantors }) => {
        await logConversionEvent({
          email: applicant.doorsteadUser.email || "",
          propertyId: applicant.applicationRequest.property?.propertyId || "",
          action: "TP_APPLICANT_INVITE_COTENANT_SUBMITTED",
          surface: "APPLICANT_PORTAL",
          data: {
            applicantId: applicant.applicantId,
            howManyAdults,
            haveGuarantor,
            coTenants,
            guarantors,
          },
        });
        window.location.href = `/application/${applicantId}?invited=true`;
      }}
    />
  );
}
