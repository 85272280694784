import { Alert, AlertDescription, AlertIcon, Box, Heading, Link, VStack } from "@chakra-ui/react";
import produce from "immer";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useApplicantId } from "src/hooks/applicants/useApplicantId";
import { getUuid } from "src/utils/getUuid";
import { useApplicantPageView } from "../../../hooks/applicants/useApplicantPageView";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { InviteCardV2 } from "./InviteCardV2/InviteCardV2";
import { NewApplicantsCardV2 } from "./NewApplicantsCardV2";
import { ApplicantTypeV2, NewApplicantWithIdV2 } from "./NewApplicantsCardV2/typings";
import { getDuplicatedEmails } from "./getDuplicatedEmails";
import { DirtyProvider } from "./useDirty";
import { FailureEmailsProvider, useFailureEmails } from "./useFailureEmails";

type Props = {
  initCoTenants: NewApplicantWithIdV2[];
  initGuarantors: NewApplicantWithIdV2[];
  onBackClick?: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => void;
  onSuccess: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => void;
  isShowSubmitButton?: ({
    coTenants,
    guarantors,
  }: {
    coTenants: NewApplicantWithIdV2[];
    guarantors: NewApplicantWithIdV2[];
  }) => boolean;
};

function Content({
  initCoTenants,
  initGuarantors,
  onBackClick = console.log,
  onSuccess,
  isShowSubmitButton = () => true,
}: Props) {
  useApplicantPageView("TP_ADD_COTENANTS_AND_GUARANTORS_VIEWED");
  const applicationMe = useFetchedApplicationMe();
  const { failureEmails, setFailureEmails } = useFailureEmails();
  const applicantId = useApplicantId();
  const [coTenants, setCoTenants] = useState<NewApplicantWithIdV2[]>(initCoTenants);
  const [guarantors, setGuarantors] = useState<NewApplicantWithIdV2[]>(initGuarantors);
  const duplicatedEmails = getDuplicatedEmails({ coTenants, guarantors });

  const onRemoveEmail = ({ email }: { email: string }) => {
    if (email !== null && failureEmails.includes(email)) {
      setFailureEmails((prev) => {
        return produce(prev, (draft) => {
          draft.splice(draft.indexOf(email), 1);
        });
      });
    }
  };

  return (
    <>
      <Link
        as={RouterLink}
        to={`/application/${applicantId}`}
        onClick={() => onBackClick({ coTenants, guarantors })}
        color="purple.600"
        fontSize={16}
        fontWeight={500}
      >
        &lt; Back
      </Link>
      <Heading variant="h4" fontSize={36} fontWeight={600} mt={2}>
        Add co-tenants and guarantors
      </Heading>
      <NewApplicantsCardV2
        id="/0"
        mt={8}
        eventName="COTENANT"
        title="Co-tenants"
        addLabel={coTenants.length === 0 ? "Add tenant" : "Add another tenant"}
        numberLabel="Co-tenant"
        duplicatedEmails={duplicatedEmails}
        subTitle={
          <VStack spacing={4} width="100%" alignItems="flex-start">
            <Box>Will you have other tenants living with you?</Box>
            <Alert status="warning" backgroundColor="purple.100" borderRadius={6}>
              <AlertIcon color="purple.400" />
              <AlertDescription>
                <b>Important: </b>
                If applying with co-tenants, each person must submit a separate application.
                <br />
                Anyone age 18 or older who plans to live with you must be added here and submit their own application.
                <br />
                Each applicant must verify their identity through Persona by taking a selfie and providing a valid ID.
              </AlertDescription>
            </Alert>
          </VStack>
        }
        values={produce(coTenants, (draft) => {
          const meIndex = draft.findIndex((e) => e.id === applicationMe.applicantById.applicantId);
          if (meIndex !== -1) {
            return [draft[meIndex], ...draft.slice(0, meIndex), ...draft.slice(meIndex + 1)];
          } else {
            return draft;
          }
        })}
        onAdd={() => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft.push({
                id: getUuid(),
                firstName: "",
                lastName: "",
                email: "",
                type: ApplicantTypeV2.TENANT,
                disabled: false,
                removable: true,
                createdAt: new Date().toISOString(),
              });
            });
          });
        }}
        onChange={(applicant, id) => {
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              const index = draft.findIndex((applicant) => applicant.id === id);
              draft[index] = { ...draft[index], ...applicant };
            });
          });
        }}
        onRemove={(id) => {
          const index = coTenants.findIndex((applicant) => applicant.id === id);
          setCoTenants((prev) => {
            return produce(prev, (draft) => {
              draft.splice(index, 1);
            });
          });
          if (index < coTenants.length && coTenants[index].email !== null) {
            onRemoveEmail({ email: coTenants[index].email });
          }
        }}
      />
      <NewApplicantsCardV2
        id="/1"
        mt={8}
        eventName="GUARANTOR"
        title="Guarantors"
        addLabel={guarantors.length === 0 ? "Add guarantor" : "Add another guarantor"}
        numberLabel="Guarantor"
        duplicatedEmails={duplicatedEmails}
        subTitle="Tenants need to demonstrate 3x monthly rent in income. Or they can add a Guarantor who can demonstrate 5x monthly rent in income."
        values={guarantors}
        onAdd={() => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft.push({
                id: getUuid(),
                firstName: "",
                lastName: "",
                email: "",
                type: ApplicantTypeV2.GUARANTOR,
                disabled: false,
                removable: true,
                createdAt: new Date().toISOString(),
              });
            });
          });
        }}
        onChange={(applicant, id) => {
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              const index = draft.findIndex((applicant) => applicant.id === id);
              draft[index] = { ...draft[index], ...applicant };
            });
          });
        }}
        onRemove={(id) => {
          const index = guarantors.findIndex((applicant) => applicant.id === id);
          setGuarantors((prev) => {
            return produce(prev, (draft) => {
              draft.splice(index, 1);
            });
          });
          if (index < guarantors.length && guarantors[index].email !== null) {
            onRemoveEmail({ email: guarantors[index].email });
          }
        }}
      />
      <InviteCardV2
        id="/2"
        mt={8}
        coTenants={coTenants}
        guarantors={guarantors}
        onBackClick={() => {
          onBackClick({ coTenants, guarantors });
        }}
        onSuccess={() => {
          onSuccess({ coTenants, guarantors });
        }}
        isShowSubmitButton={isShowSubmitButton}
      />
    </>
  );
}

export function AddCotenantsAndGuarantorsPageV2(props: Props) {
  return (
    <FailureEmailsProvider>
      <DirtyProvider>
        <Content {...props} />
      </DirtyProvider>
    </FailureEmailsProvider>
  );
}
