import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useIsZtpo() {
  const { isLoading, data } = useFetchApplicationMe();
  if (isLoading) return false;

  return (
    data?.applicantById?.applicationRequest?.listing?.renewal?.contract?.contractRequest?.multistepFlowType ===
    "tpo_zillow"
  );
}
