import { Box, Center, HStack, Stack, VStack } from "@chakra-ui/react";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useAddress } from "../../../hooks/applicants/useAddress";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { useGoogleMapKey } from "../../../hooks/useGoogleMapKey";
import { getSegmentedAddress } from "../../../hooks/useSegmentedAddress";
import { StartButton } from "../ApplicantLandingCard/StartButton";
import { ApplicantFeeBox } from "./ApplicantFeeBox/ApplicantFeeBox";
import { ThankYouBox } from "./ApplicantFeeBox/ThankYouBox";
import { InfoGrid } from "./InfoGrid";

export function ApplicantLandingCardV2() {
  const isMobile = useIsMobile();
  const address = useAddress();
  const googleMapKey = useGoogleMapKey();
  const {
    applicationRequest: { property },
  } = useFetchedApplicant();
  const [seg1, seg2] = getSegmentedAddress(property);
  const me = useFetchedApplicationMe();

  return (
    <VStack w="100%" alignItems="center" spacing={5}>
      {me.applicantById.doorsteadUser.lastName && (
        <Center fontWeight="bold" color="#1A202C" fontSize="3xl">
          Hello, {me.applicantById.doorsteadUser.lastName}
        </Center>
      )}
      <VStack w="100%" alignItems="flex-start" bgColor="white" borderRadius={20} p={isMobile ? 4 : 10} spacing={5}>
        <HStack w="100%" alignItems="center" spacing={5}>
          {!isMobile && (
            <Box w="fit-content" h="100%">
              <GoogleMapImage
                borderRadius={6}
                width={isMobile ? 322 : 240}
                height={isMobile ? 220 : 220}
                address={address}
                googleKey={googleMapKey}
              />
            </Box>
          )}
          <VStack alignItems="flex-start" w="100%" spacing={3}>
            <Box color="gray.500" fontSize="md" lineHeight="24px">
              Rental property address
            </Box>
            <VStack spacing={0} alignItems="flex-start" fontWeight="bold" fontSize="xl" color="gray.700">
              <Box>{seg1}</Box>
              <Box>{seg2}</Box>
            </VStack>
            <InfoGrid />
          </VStack>
        </HStack>
        {!isMobile && <ThankYouBox />}
        <Stack
          direction={isMobile ? "column" : "row"}
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          <ApplicantFeeBox />
          {isMobile && <ThankYouBox />}
          <StartButton />
        </Stack>
      </VStack>
    </VStack>
  );
}
