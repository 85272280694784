import Hotjar from "@hotjar/browser";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { StatsigProvider, useGate } from "statsig-react";
import { LeaseNegotiationOnePage } from "./components/LeaseNegotiationOnePage";
import {
  SENTRY_DSN,
  appServerUrl,
  auth0Config,
  getDoorsteadEnv,
  segmentWriteKey,
  statsigEnvironment,
  statsigSdkKey,
} from "./config";
import { AnalyticsProvider } from "./contexts/analytics";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useIsOffline } from "./hooks/useIsOffline";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ApplicantInvitePage from "./routes/applicants/applicant-invite";
import ApplicantLeaseStartDateRoute from "./routes/applicants/applicant-lease-start-date";
import ApplicantLoginPage from "./routes/applicants/applicant-login";
import ApplicantLoginCallbackPage from "./routes/applicants/applicant-login-callback";
import ApplicantLogoutPage from "./routes/applicants/applicant-logout";
import ApplicantLogoutCallbackPage from "./routes/applicants/applicant-logout-callback";
import ApplicantOnePage from "./routes/applicants/applicant-one";
import ApplicantSignUpPage from "./routes/applicants/applicant-signup";
import AutoPayPage from "./routes/autopay";
import ErrorPage from "./routes/error";
import FlexWalkthroughOnePage from "./routes/flex-walkthrough/FlexWalkthroughOnePage";
import ForgotPasswordPage from "./routes/forgot-password";
import HelpPage from "./routes/help";
import HomePage from "./routes/home/home";
import LoginPage from "./routes/login";
import LoginCallbackPage from "./routes/login/callback";
import LogoutPage from "./routes/logout";
import MaintenancePage from "./routes/maintenance";
import MakePaymentPage from "./routes/make-payment";
import NotFoundPage from "./routes/notfound";
import OneTimePay from "./routes/one-time-pay";
import PaymentMethodsPage from "./routes/payment-method";
import RentReadyDebugPage from "./routes/rent-ready/RentReadyDebugPage";
import RentReadyOnePage from "./routes/rent-ready/RentReadyOnePage";
import ProjectRoute from "./routes/rr-project/ProjectRoute";
import SignupPage from "./routes/signup";
import TransactionHistoryPage from "./routes/transaction-history";
import VendorOnboardingPage from "./routes/vendor-onboarding";
import { isOnline } from "./utils/isOnline";

if (isOnline() && SENTRY_DSN) {
  console.log("sentry init");
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getDoorsteadEnv(),
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  const siteId = 3716052;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

const Maintain = ({ element }: { element: React.ComponentType }) => {
  const isMaintenanceMode = useGate("tenant_portal_maintenance_mode").value;
  const Component = isMaintenanceMode ? MaintenancePage : element;
  return <Component />;
};

function Routes() {
  switch (window.location.hostname) {
    case "pay.doorstead.com":
    case "mars-pay.doorstead.com":
    case "int-pay.doorstead.com":
      return (
        <Route path="/" errorElement={<ErrorPage />}>
          <Route index element={<OneTimePay />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      );
    case "vendors.doorstead.com":
    case "mars-vendors.doorstead.com":
    case "int-vendors.doorstead.com":
      return (
        <Route path="/" errorElement={<ErrorPage />}>
          <Route path="onboarding" element={<VendorOnboardingPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      );
    default:
      return (
        <Route path="/" errorElement={<ErrorPage />}>
          <Route errorElement={<ErrorPage />}>
            <Route index element={<Maintain element={HomePage} />} />
            <Route path="lsd" element={<LeaseNegotiationOnePage />} />
            <Route path="payment-method" element={<Maintain element={PaymentMethodsPage} />} />
            <Route path="payment" element={<Maintain element={MakePaymentPage} />} />
            <Route path="transaction-history" element={<Maintain element={TransactionHistoryPage} />} />
            <Route path="autopay" element={<Maintain element={AutoPayPage} />} />
            <Route path="application/login/callback" element={<ApplicantLoginCallbackPage />} />
            <Route path="application/logout/callback" element={<ApplicantLogoutCallbackPage />} />
            <Route path="application/logout" element={<ApplicantLogoutPage />} />
            <Route path="application/:id/signup" element={<ApplicantSignUpPage />} />
            <Route path="application/:id/login" element={<ApplicantLoginPage />} />
            <Route path="application/:id/invite" element={<ApplicantInvitePage />} />
            <Route path="application/:id/lease-start-date" element={<ApplicantLeaseStartDateRoute />} />
            <Route path="application/:id" element={<ApplicantOnePage />} />
            <Route path="application/v2/:id" element={<ApplicantOnePage />} />
            <Route path="rent-ready/:inspectionReportId" element={<RentReadyOnePage />} />
            <Route path="flex-walkthrough/:flexWalkthroughReportId" element={<FlexWalkthroughOnePage />} />
            <Route path="rent-ready/:inspectionReportId/debug" element={<RentReadyDebugPage />} />
            <Route path="project/:rrProjectId" element={<ProjectRoute />} />
            <Route path="one-time-pay" element={<OneTimePay />} />
            <Route path="vendor-onboarding" element={<VendorOnboardingPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route path="login" element={<LoginPage />}>
              <Route path="callback" element={<LoginCallbackPage />} />
            </Route>
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="help" element={<HelpPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      );
  }
}

const router = createBrowserRouter(createRoutesFromElements(Routes()));

export const StatsigSetup = ({ children, customIDs }: PropsWithChildren & { customIDs?: Record<string, string> }) => {
  const isOffline = useIsOffline();
  const { user, loading } = useAuth();
  if (isOffline) {
    return <>{children}</>;
  }
  if (loading) {
    return null;
  }

  return (
    <StatsigProvider
      sdkKey={statsigSdkKey}
      user={{
        userID: user?.doorsteadUserId,
        email: user?.email,
        customIDs,
      }}
      waitForInitialization={true}
      options={{
        environment: { tier: statsigEnvironment() },
      }}
    >
      {children}
    </StatsigProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <AnalyticsProvider segmentWriteKey={segmentWriteKey()}>
      <AuthProvider auth0Config={auth0Config()} baseUrl={appServerUrl()}>
        <StatsigSetup>
          <QueryClientProvider
            client={
              new QueryClient({
                defaultOptions: {
                  queries: {
                    refetchOnWindowFocus: false,
                    refetchOnReconnect: false,
                    refetchOnMount: false,
                  },
                },
              })
            }
          >
            <HelmetProvider>
              <RouterProvider router={router} />
            </HelmetProvider>
          </QueryClientProvider>
        </StatsigSetup>
      </AuthProvider>
    </AnalyticsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Unregister service worker and reload
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister().then(() => {
      window.location.reload();
    });
  });
}
