import { Box, Flex, FlexProps, HStack } from "@chakra-ui/react";
import { useIsZtpo } from "../../../../hooks/applicants/useIsZtpo";
import { LimitedTimeDiscountBox } from "./LimitedTimeDiscountBox";
import { PriceBox } from "./PriceBox";

export function ApplicantFeeDesktopBox({ ...flexProps }: FlexProps) {
  const isZtpo = useIsZtpo();
  return (
    <Flex {...flexProps} justifyContent="space-between" bgColor="#F7FAFC" borderRadius={6} p={4} fontWeight="bold">
      <HStack>
        <Box color="#1A202C">Application fee</Box>
        {isZtpo && <LimitedTimeDiscountBox />}
      </HStack>
      <PriceBox />
    </Flex>
  );
}
