import { Step } from "@doorstead/components/containers/LayeredTimeline/typings";
import produce from "immer";
import React from "react";
import { useUseApplicationFlowV2 } from "src/hooks/applicants/useUseApplicationFlowV2";
import useIntersectionObserver from "../useIntersectionObserver";
import { useApplicantOnboardingStage } from "./useApplicantOnboardingStage";
import { ApplicantOnboardingStage } from "./useFetchApplicationMe/Applicant";
import { useIsAdditionalOccupantsFeature } from "./useIsAdditionalOccupantsFeature";
import { useIsPrimaryApplicant } from "./useIsPrimaryApplicant";

export const yourInformationTitle = "Your Information";

export const documentTitle = "Documents";

export const employmentTitle = "Employment verification";

export const submitAndPayTitle = "Submit and Pay";

function getInitialSteps(isAdditionalOccupantsFeature: boolean, showLeaseStartDateSelect: boolean): Step[] {
  const yourInformationStep: Step = {
    id: "/0",
    name: yourInformationTitle,
    status: "upcoming",
    cursor: "pointer",
    children: [
      {
        id: "/0/children/0",
        name: "Name + contact info",
        status: "visited",
      },
      {
        id: "/0/children/1",
        name: "Current address",
        status: "visited",
      },
      {
        id: "/0/children/2",
        name: "Previous residence",
        status: "visited",
      },
    ],
  };
  if (isAdditionalOccupantsFeature) {
    yourInformationStep.children = yourInformationStep.children.concat([
      {
        id: "/0/children/3",
        cursor: "pointer",
        name: "Occupants",
        status: "visited",
      },
      {
        id: "/0/children/4",
        name: "Pets",
        status: "visited",
      },
    ]);
  } else {
    yourInformationStep.children.push({
      id: "/0/children/3",
      cursor: "pointer",
      name: "Pets",
      status: "visited",
    });
  }

  const steps: Step[] = [
    yourInformationStep,
    {
      id: "/1",
      cursor: "pointer",
      name: documentTitle,
      status: "upcoming",
      children: [
        {
          id: "/1/children/0",
          name: "Photo ID",
          status: "visited",
        },
        {
          id: "/1/children/1",
          name: "Background check",
          status: "visited",
        },
      ],
    },
    {
      id: "/2",
      cursor: "pointer",
      name: employmentTitle,
      status: "upcoming",
      children: [
        {
          id: "/2/children/0",
          name: "Current employment",
          status: "visited",
        },
        {
          id: "/2/children/1",
          name: "Proof of income",
          status: "visited",
        },
      ],
    },
  ];

  if (showLeaseStartDateSelect) {
    steps.push({
      id: "/3",
      cursor: "pointer",
      name: submitAndPayTitle,
      status: "upcoming",
      children: [
        {
          id: "/3/children/0",
          name: "Release of information",
          status: "visited",
        },
        {
          id: "/3/children/1",
          name: "Lease start date",
          status: "visited",
        },
        {
          id: "/3/children/2",
          name: "Pay Application fee",
          status: "visited",
        },
      ],
    });
  } else {
    steps.push({
      id: "/3",
      cursor: "pointer",
      name: submitAndPayTitle,
      status: "upcoming",
      children: [
        {
          id: "/3/children/0",
          name: "Release of information",
          status: "visited",
        },
        {
          id: "/3/children/1",
          name: "Pay Application fee",
          status: "visited",
        },
      ],
    });
  }

  return steps;
}

function getSteps(targetId: string, isAdditionalOccupantsFeature: boolean, showLeaseStartDateSelect: boolean): Step[] {
  const targetIds = targetId.split("/").slice(1);
  const targetRootId = Number(targetIds[0]);

  const initialSteps = getInitialSteps(isAdditionalOccupantsFeature, showLeaseStartDateSelect);
  return produce(initialSteps, (draft) => {
    draft.forEach((step) => {
      const stepId = step.id;
      const stepIds = stepId.split("/").slice(1);
      const stepRootId = Number(stepIds[0]);
      if (stepRootId < targetRootId) {
        step.status = "visited";
      } else if (stepRootId === targetRootId) {
        step.status = "current";
      } else {
        step.status = "upcoming";
      }

      //  child layers
      step.children.forEach((childStep) => {
        const childStepId = childStep.id;
        if (childStepId === targetId) {
          childStep.status = "current";
        } else {
          const arr = childStepId.split("/").slice(1);
          if (
            arr.length === targetIds.length &&
            arr.length === 3 &&
            arr[0] === targetIds[0] &&
            arr[1] === targetIds[1]
          ) {
            if (arr[2] <= targetIds[2]) {
              childStep.status = "visited";
            } else if (arr[2] === targetIds[2]) {
              childStep.status = "current";
            } else {
              childStep.status = "upcoming";
            }
          } else {
            childStep.status = "upcoming";
          }
        }
      });
    });
  });
}

export function getApplicantStatusByTargetId(targetId: string): ApplicantOnboardingStage {
  const rootTargetId = Number(targetId.split("/").slice(1)[0]);
  if (rootTargetId === 0) return ApplicantOnboardingStage.YOUR_INFORMATION_SECTION;
  else if (rootTargetId === 1) return ApplicantOnboardingStage.DOCUMENTS_SECTION;
  else if (rootTargetId === 2) return ApplicantOnboardingStage.EMPLOYMENT_VERIFICATIONS_SECTION;
  else if (rootTargetId === 3) return ApplicantOnboardingStage.SUBMIT_AND_PAY_SECTION;

  return ApplicantOnboardingStage.YOUR_INFORMATION_SECTION;
}

export function getTargetId(stage: ApplicantOnboardingStage | null): string {
  if (stage === null) return "/0";

  switch (stage) {
    case ApplicantOnboardingStage.YOUR_INFORMATION_SECTION:
      return "/0";
    case ApplicantOnboardingStage.DOCUMENTS_SECTION:
      return "/1";
    case ApplicantOnboardingStage.EMPLOYMENT_VERIFICATIONS_SECTION:
      return "/2";
    case ApplicantOnboardingStage.SUBMIT_AND_PAY_SECTION:
      return "/3";
    default:
      return "/0";
  }
}

export function useSteps(): Step[] {
  const isAdditionalOccupantsFeature = useIsAdditionalOccupantsFeature();
  const useApplicationFlowV2 = useUseApplicationFlowV2();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const previousTargetIdRef = React.useRef<null | string>(null);
  const onboardingStage = useApplicantOnboardingStage();
  const [steps, setSteps] = React.useState(
    getSteps(getTargetId(onboardingStage), isAdditionalOccupantsFeature, useApplicationFlowV2 && isPrimaryApplicant)
  );

  React.useEffect(() => {
    setSteps(
      getSteps(getTargetId(onboardingStage), isAdditionalOccupantsFeature, useApplicationFlowV2 && isPrimaryApplicant)
    );
  }, [onboardingStage]);

  useIntersectionObserver("0px", 0.5, "/", (node) => {
    const targetId = node.target.id;
    if (previousTargetIdRef.current !== targetId) {
      setSteps(getSteps(targetId, isAdditionalOccupantsFeature, useApplicationFlowV2 && isPrimaryApplicant));
      previousTargetIdRef.current = targetId;
    }
  });

  return steps;
}
