import { Box, VStack } from "@chakra-ui/react";
import { useIsPrimaryApplicant } from "src/hooks/applicants/useIsPrimaryApplicant";
import { useUseApplicationFlowV2 } from "src/hooks/applicants/useUseApplicationFlowV2";
import { useApplicantPageView } from "../../../../../hooks/applicants/useApplicantPageView";
import { useFetchMe } from "../../../../../hooks/useFetchMe";
import { EverGreenPaymentMethods } from "../../../../evergreens/EverGreenPaymentMethods";
import { padding, width } from "../../config";
import { LeaseStartDateCard } from "./LeaseStartDateCard/LeaseStartDateCard";
import { PayCard } from "./PayCard";
import { ReleaseFormCard } from "./ReleaseFormCard";

function Content() {
  const useApplicationFlowV2 = useUseApplicationFlowV2();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  useApplicantPageView("TP_SUBMIT_AND_PAY_VIEWED");

  if (useApplicationFlowV2 && isPrimaryApplicant) {
    return (
      <VStack spacing={8} id="/3">
        <Box id="/3/children/0" w="100%">
          <ReleaseFormCard padding={padding} width={width} />
        </Box>
        <Box id="/3/children/1" w="100%">
          <LeaseStartDateCard padding={padding} width={width} />
        </Box>
        <Box id="/3/children/2" w="100%">
          <EverGreenPaymentMethods />
        </Box>
        <Box id="/3/children/3" w="100%">
          <PayCard />
        </Box>
      </VStack>
    );
  } else {
    return (
      <VStack spacing={8} id="/3">
        <Box id="/3/children/0" w="100%">
          <ReleaseFormCard padding={padding} width={width} />
        </Box>
        <Box id="/3/children/1" w="100%">
          <EverGreenPaymentMethods />
        </Box>
        <Box id="/3/children/2" w="100%">
          <PayCard />
        </Box>
      </VStack>
    );
  }
}

export function SubmitAndPaySection() {
  const { isFetching, isFetched } = useFetchMe();

  if (isFetching || !isFetched) {
    return null;
  }
  return <Content />;
}
