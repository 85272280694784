import { Box, HStack, VStack } from "@chakra-ui/react";
import { useIsZtpo } from "../../../../hooks/applicants/useIsZtpo";
import { LimitedTimeDiscountBox } from "./LimitedTimeDiscountBox";
import { PriceBox } from "./PriceBox";

export function ApplicantFeeMobileBox() {
  const isZtpo = useIsZtpo();
  return (
    <VStack alignItems="flex-start" w="100%" spacing={1}>
      <Box fontSize="14px" fontWeight="semibold" color="#A0AEC0">
        Application fee
      </Box>
      <HStack color="#1A202C">
        <PriceBox /> <Box>per applicant</Box>
      </HStack>
      {isZtpo && <LimitedTimeDiscountBox />}
    </VStack>
  );
}
