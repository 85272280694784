import { useLocation } from "react-router-dom";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useIsWelcomeV2Feature(): boolean {
  const { data } = useFetchApplicationMe();
  const { pathname } = useLocation();
  if (!pathname.startsWith("/application/v2")) {
    return false;
  }
  return !!data?.applicantById.applicationRequest.isWelcomeV2Feature;
}
