import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useUseApplicationFlowV2(): boolean {
  const { isLoading, data } = useFetchApplicationMe();
  if (isLoading) return false;

  return (
    !!data?.applicantById.applicationRequest.useApplicationFlowV2 &&
    !!data?.applicantById.applicationRequest.property.isTpo &&
    data?.applicantById.applicationRequest.listing.renewal.contract.contractRequest?.multistepFlowType === "tpo_zillow"
  );
}
